* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --accent: rgba(0, 255, 183,1);
}

html {
  scroll-behavior: smooth;
}

.card {
  border-radius: 0%; /* Set border radius to 0% */
  /* Add any other styles you need for the card */
}

img {
  border-radius: 0px;
}


a {
  text-decoration: none; /* Remove underline */
  color: inherit;       /* Use the inherited color */
}

.user img {
  border: 2px solid white; 
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.564);
}
.user img:hover {
  border: 2px solid white; 
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.564);
}


.tools {
  display: flex;
  align-items: stretch;
  max-width: 100%;
  flex-wrap: wrap;
}
  .tools img {
    object-fit: contain;
    width: auto;
    height: 1.4em;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    transition: 0.1s;
  }

  .tools img:hover {
    opacity: 0.4;
    transform: scale(1.05);
  }

  .tools > :not(:first-child) {
    margin-left: 5px;
  }


#slider-parent {
  position: relative;
  height: 25%;
  min-height: 10em;
}

.slider{
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  background:
      linear-gradient(
          to right,
          rgba(159, 138, 190, 0.3),
          rgba(159, 138, 190, 0)
      );
  margin-top: 0.5rem;
  border-radius: 5px;
  margin-right: 0%;
}

  .slider .bar{
    width: 100%;
    height: 33%;
    background: linear-gradient(
        to right,
        rgba(0, 251, 255,1),
        rgba(0, 251, 255,0)
    );
    border-radius: 5px;
  }

  /*Animations*/
  .slider .bar {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
  }

  .slider-anim-0 {
    transform: translateY(0px); 
  }

  .slider-anim-50 {
    transform: translateY(100%); 
  }

  .slider-anim-100 {
    transform: translateY(200%); 
  }

  @media screen and (orientation: portrait) {
    .slider-anim-0 {
      transform: translateX(0px); 
    }
    
    .slider-anim-50 {
      transform: translateX(100%); 
    }
    
    .slider-anim-100 {
      transform: translateX(200%); 
    }
  }


h1 {
  color: #ffffff;
  font-weight: 600;
  font-size: 3rem;
  opacity: 0.8;
}

img {
  width: 45%;
  border-radius: 5px;
}

h2,
p {
  color: white;
  font-weight: 500;
  opacity: 0.8;
}

h3 {
  color: white;
  font-weight: 600;
  opacity: 0.8;
}

.App {
  background: linear-gradient(
      to right top,
      #001f32,
      #112240 
  );
  overflow: hidden;
}

main {
  font-family: "Lato", sans-serif;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.glass {
  background: rgb(255, 255, 255);
  min-height: 90vh;
  width: 83%;
  background: linear-gradient(
      to right bottom,
      rgba(0, 60, 78, 0.719),
      rgba(0, 255, 183,0.2)
  );
  border-radius: 1rem;
  z-index: 3;
  backdrop-filter: blur(2rem);
  display: flex;
  font-family: "Exo 2", sans-serif;
}

.circle1, 
.circle2 {
  background: linear-gradient(
      to right bottom,
      rgb(40, 40, 253, 0.9),
      rgba(22, 10, 49, 0.2)
  );
  height: 20rem;
  width: 20rem;
  position: absolute;
  top: 5%;
  border-radius: 50%;
}

.circle1 {
  top: 15%;
  left: 5%;
}

.circle2 {
  right: 5%;
  top: 58%;
}

.fadeOut {
  opacity: 0;
}
.fadeIn {
  opacity: 1;
}

.user {color: white;}

.dashboard {
  padding-top: 3rem;
  flex: 0.5;
  display: inline-block;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: linear-gradient( 
      to right bottom, 
      rgba(0, 11, 50, 70%), 
      rgba(35, 0, 83, 0.3) 
  );
  border-radius: 1rem;
  font-family: 'SF Mono','Fira Code','Fira Mono','Roboto Mono',monospace;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}
.links{
  position: relative;
  padding-top: 0rem;
  padding-left: 0rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  align-items: center;
}

.link {
  display: flex;
  margin: 0rem 0rem;
  padding: 1rem 3.4rem;
}

  .link h2{
    padding: 0rem 2rem;
    font-size: 1rem;
    cursor: pointer;
  }

  .link h2:hover{
    color: var(--accent);
    transition: 0.5s;
  }

#1, #2, #3 {
  overflow-y: auto;
  scroll-behavior: smooth;
  transition: transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s;
}

.section {
  flex: 2;
  display: inline-block;
  flex-direction: column;
  justify-content: left;
  margin-left: 5%;
  margin-right: 5%;
  transition: transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s;
  position: relative;
  z-index:3;
  overflow-y: auto;
  height: 35rem;
  -webkit-mask-image: -webkit-gradient(
    linear, left top, left bottom, 
    color-stop(0%, rgba(0, 0, 0, 0)), 
    color-stop(2%, rgba(0, 0, 0, 1)), 
    color-stop(98%, rgba(0, 0, 0, 1)), 
    color-stop(100%, rgba(0, 0, 0, 0)) 
  );
}

.visible {
  opacity: 1;
  display: flex;
  transition: opacity 0.5s ease-in-out;
}

.bottom-text {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 10px 0;
  font-size: 16px; /* Adjust as needed */
}

.showcase {
  width: 100%;
}

  .showcase img {
    width: inherit;
  }

.card {
  border: 1px solid var(--accent);
  display: flex;
  background: linear-gradient(
      to right top,
      rgba(0, 63, 122, 0.8),
      rgba(0, 0, 0, 0.5)
  );
  border-radius: 0.3rem;
  stroke-width: 2px;
  margin: 2rem 0rem;
  padding: 2rem;
  box-shadow: 6px 6px 20px rgba(122,122,122,0.2);
  justify-content: left;
}

.un {
  display: inline-block;
  position: relative;
  padding-top: 8%;
}

  .un:after {
    content: '';
    width: 0px;
    height: 2px;
    position: absolute;
    top: 100%;
    left: 0;
    background: var(--accent);
    transition: 300ms;
  }

  .un:hover:after {
    width: 100%;
  }

  .un:not(:hover):after {
    right: 0;
    left: auto;
  }

#case {
  position: relative; 
  display: inline-flex;
}

